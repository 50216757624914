<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    :title="
      modeEdit
        ? $t('tabs.edituser') + ' ' + userData.userName
        : $t('tabs.addnewuser')
    "
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    size="lg"
    @change="(val) => ShowSidebar(val)"
  >
    <template #default="{ hide }">
      <div>
        <b-tabs v-model="tabIndex" pills>
          <b-tab
            v-for="(tabsOption, indexTr) in tabsTypeUserOptionsComouted"
            :key="indexTr"
            active
          >
            <!-- Btabs -->
            <template #title>{{ tabsOption }} </template>
            <!-- <template #title>{{ $t("tabs.player") }} </template> -->
            <div>
              <b-tabs content-class="mt-3">
                <b-tab active>
                  <template #title>{{ $t("tabs.login") }} </template>
                  <!-- username -->
                  <validation-provider
                    #default="validationContext"
                    name="User name"
                    rules="required"
                  >
                    <b-form-group label-for="username">
                      <template #label>{{ $t("labels.username") }} </template>
                      <b-form-input
                        :id="'username' + indexTr"
                        v-model="userData.userName"
                        @input="autocomplete()"
                        :state="getValidationState(validationContext)"
                        trim
                        :disabled="modeEdit"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Password -->
                  <b-form-group v-if="!modeEdit">
                    <label for="password">{{ $t("labels.password") }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      :rules="whitelabelRule"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          :id="'password' + indexTr"
                          v-model="userData.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Confirm Password -->
                  <b-form-group v-if="!modeEdit">
                    <label for="password">{{
                      $t("labels.confirPassword")
                    }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="ConfirmPassword"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          :id="'confirmpasswords' + indexTr"
                          v-model="userData.confirmpassword"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- CHANGE BRANCH -->
                  <div
                    class="mt-1"
                    v-if="
                      ((modeEdit &&
                        userData.typeUser !== 'Admin' &&
                        userLogged.typeUser == 'Root') ||
                        userLogged.typeUser == 'Admin') &&
                      changeClient
                    "
                  >
                    <div class="d-flex align-items-center">
                      <span class="mr-1">{{ $t("parent_user") }}:</span>
                      <b-button
                        @click="showHeranchyChangeRama()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                      >
                        <span v-if="!selectUserChangeRama.name">{{
                          userData.parentUserName
                        }}</span>
                        <span v-else>{{ selectUserChangeRama.name }}</span>
                        <feather-icon class="ml-1" icon="RepeatIcon" />
                      </b-button>
                    </div>
                    <div v-if="selectUserChangeRama.name">
                      <b-card-text class="mb-0">
                        {{ $t("affect_the_entire_branch") }}
                      </b-card-text>
                      <b-form-checkbox
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        v-model="userData.branchAll"
                      />
                    </div>
                    <b-modal
                      id="show-heranchy-change-rama"
                      :title="$t('select_the_parent_user')"
                      :visible="showHeranchy"
                      shadow
                      backdrop
                      no-close-on-backdrop
                      hide-footer
                      @change="(val) => showHeranchyChangeRamaFalse(val)"
                    >
                      <HierarchyUsers
                        @selectUserOne="selectUserHierarchyChangeRama"
                      />
                    </b-modal>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.information") }} </template>
                  <!-- affiliate-code -->
                  <div
                    class="d-flex align-items-center"
                    v-if="userData.typeUser == 'Agent'"
                  >
                    <b-form-group :label-for="'affiliate-code' + indexTr">
                      <template #label
                        >{{ $t("labels.affiliate-code") }}
                      </template>
                      <b-form-input
                        class="text-danger"
                        :id="'affiliate-code' + indexTr"
                        v-model="userData.affiliateCode"
                        placeholder="XXX-XXX-XXX"
                      />
                    </b-form-group>
                    <div class="ml-1">
                      <b-button
                        v-b-tooltip.hover.top="$t('tooltips.generate-code')"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon rounded-circle"
                        @click="generateAffiliateCode"
                      >
                        <feather-icon icon="RefreshCwIcon" />
                      </b-button>
                    </div>
                  </div>

                  <!-- firstname -->
                  <validation-provider
                    #default="validationContext"
                    name="First Name"
                    rules=""
                  >
                    <b-form-group :label-for="'firstname' + indexTr">
                      <template #label>{{ $t("labels.firstname") }} </template>
                      <b-form-input
                        :id="'firstname' + indexTr"
                        v-model="userData.firstName"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- lastname -->
                  <validation-provider
                    #default="validationContext"
                    name="Last Name"
                    rules=""
                  >
                    <b-form-group label-for="lastname">
                      <template #label>{{ $t("labels.lastname") }} </template>
                      <b-form-input
                        :id="'lastname' + indexTr"
                        v-model="userData.lastName"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="John Doe"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Email -->
                  <validation-provider
                    #default="validationContext"
                    name="Email"
                    :rules="[rules.required, rules.email]"
                  >
                    <b-form-group label-for="email">
                      <template #label>{{ $t("labels.email") }} </template>
                      <b-form-input
                        :id="'email' + indexTr"
                        v-model="userData.email"
                        :state="getValidationState(validationContext)"
                        trim/>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Address -->
                  <validation-provider
                    #default="validationContext"
                    name="Address"
                    rules=""
                  >
                    <b-form-group label-for="address">
                      <template #label>{{ $t("labels.address") }} </template>
                      <b-form-textarea
                        :id="'address' + indexTr"
                        v-model="userData.address"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Phone -->
                  <validation-provider
                    #default="validationContext"
                    name="Phone"
                    rules="onlyDigits"
                  >
                    <b-form-group label-for="phone">
                      <template #label>{{ $t("labels.phone") }} </template>
                      <b-form-input
                        :id="'phone' + indexTr"
                        v-model="userData.phone"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="Country"
                    rules="country"
                  >
                    <b-form-group label-for="country">
                      <template #label
                        >{{ $t("labels.country_of_residence") }}
                      </template>
                      <b-form-input
                        :id="'country' + indexTr"
                        v-model="userData.country"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Date Of Birth -->
                  <validation-provider
                    #default="validationContext"
                    name="Date Of Birth"
                    rules="required"
                  >
                    
                  <b-form-group class="form-group" :label-for="'dateOfBirth' + indexTr">
                      <template #label
                        >{{ $t("labels.dateofbirth") }}
                      </template>

                    <flat-pickr
                      :id="'dateOfBirth' + indexTr"
                      class="form-control"
                      v-model="userData.birthdate"
                      :placeholder="$t('labels.date')"
                      :config="{
                        altInput: true,
                        altFormat: 'd-m-Y',
                        dateFormat: 'Y-m-d',
                        enableTime: false,
                        wrap : true,
                        plugins: [
                           new yearDropdownPlugin({
                             yearStart: 100,
                             yearEnd: 0
                          })
                         ],
                        }"
                      />

                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  
                    </b-form-group>
                  </validation-provider>
                  <!-- DNI -->
                  <validation-provider
                    #default="validationContext"
                    name="DNI"
                    rules="dni"
                  >
                    <b-form-group label-for="dni">
                      <template #label>{{ $t("labels.dni") }} </template>
                      <b-form-input
                        :id="'dni' + indexTr"
                        v-model="userData.dni"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-tab>
                <b-tab>
                  <template #title> {{ $t("tabs.permissions") }} </template>
                  <!-- Client -->
                  <validation-provider
                    #default="validationContext"
                    name="Client"
                    rules="required"
                    v-if="userLogged.typeUser == 'Root' && modeEdit"
                  >
                    <b-form-group label="Client" label-for="client">
                      <v-select
                        v-model="userData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="clientsOptions"
                        @option:selected="selectedClient"
                        :reduce="(e) => e._id"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- whitelabel -->
                  <validation-provider
                    #default="validationContext"
                    name="whitelabel"
                    rules="required"
                  >
                    <b-form-group label-for="whitelabel">
                      <template #label>{{ $t("labels.whitelabel") }} </template>
                      <v-select
                        v-model="userData.whitelabels"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="whitelabelOptions"
                        :reduce="(e) => e._id"
                        :clearable="false"
                        :disabled="true"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Currencies-->
                  <validation-provider
                    #default="validationContext"
                    name="Currencies"
                    rules="required"
                  >
                    <b-form-group label="Currencies" label-for="currencie">
                      <template #label>{{ $t("labels.currencies") }} </template>
                      <v-select
                        v-model="userData.currencies"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="currenciesOptions"
                        :disabled="!changeClient"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Products -->
                  <validation-provider
                    #default="validationContext"
                    name="Products"
                    rules="required"
                  >
                    <!--:disabled="changeBranchActive || !changeClient"-->
                    <b-form-group label-for="products">
                      <template #label>{{ $t("labels.products") }} </template>
                      <v-select
                        v-model="userData.products"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :options="productsOptions"
                        @input="selectedProducts"
                        :disabled="!changeClient"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Role -->
                  <validation-provider
                    #default="validationContext"
                    name="Role"
                    rules="required"
                    v-if="
                      ((userData.typeUser == 'Admin' ||
                        userData.typeUser == 'Agent') &&
                        userLogged.typeUser == 'Root') ||
                      userLogged.typeUser == 'Admin'
                    "
                  >
                    <b-form-group label-for="role">
                      <template #label> {{ $t("labels.roles") }} </template>
                      <v-select
                        v-model="userData.roles"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :reduce="(e) => e._id"
                        :options="rolesOptions"
                        :disabled="userLogged.typeUser != 'Root'"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-tab>
                
<!--                <b-tab-->
<!--                  v-if="-->
<!--                    userData.typeUser == 'Admin' || userData.typeUser == 'Agent'-->
<!--                  "-->
<!--                >-->
<!--&lt;!&ndash;                  <template #title> {{ $t("tabs.comisions") }} </template>&ndash;&gt;-->
<!--                  <template #title-->

<!--                  > {{ $t("tabs.brands_comisions") }} </template>-->

<!--                    <b-list-group v-if="userData.commissions.length > 0">-->
<!--                    <b-list-group-item variant="primary">-->
<!--                      <b-row>-->
<!--                        <b-col md="4"> General </b-col>-->
<!--                        <b-col md="4">-->
<!--                          <v-select-->
<!--                            class="bg-white"-->
<!--                            v-model="comisionGeneral.commissionTypes"-->
<!--                            :options="typeComissionsOptions"-->
<!--                            :clearable="false"-->
<!--                            @input="-->
<!--                              changeCommissionGeneral($event, 'commissionTypes')-->
<!--                            "-->
<!--                          />-->
<!--                        </b-col>-->
<!--                        <b-col md="4">-->
<!--                          <money-->
<!--                            v-model="comisionGeneral.percent"-->
<!--                            v-bind="{ suffix: ' %', masked: false }"-->
<!--                            class="form-control"-->
<!--                            @input="changeCommissionGeneral($event, 'percent')"-->
<!--                          />-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                    </b-list-group-item>-->

<!--                    <b-list-group-item-->
<!--                      v-for="(tr, indexTr) in userData.commissions"-->
<!--                      :key="indexTr"-->
<!--                    >-->
<!--                      <b-row>-->
<!--                        <b-col md="4">-->
<!--                          {{ tr.productId }}-->
<!--                        </b-col>-->
<!--                        <b-col md="4">-->
<!--                          <v-select-->
<!--                            v-model="tr.commissionTypes"-->
<!--                            :options="typeComissionsOptions"-->
<!--                            :clearable="false"-->
<!--                          />-->
<!--                        </b-col>-->
<!--                        <b-col md="4">-->
<!--                          <money-->
<!--                            v-model="tr.percent"-->
<!--                            v-bind="{ suffix: ' %', masked: false }"-->
<!--                            class="form-control"-->
<!--                          />-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                    </b-list-group-item>-->
<!--                  </b-list-group>-->
<!--                  <div v-else class="text-center">-->
<!--                    <h1>{{ $t('user-list.text.noPoseeComisiones') }}</h1>-->
<!--                  </div>-->
<!--                </b-tab>-->

                <b-tab
                  v-if="
                    userData.typeUser == 'Admin' || userData.typeUser == 'Agent'
                  "
                >
                  <template #title>
                      <div>
                    {{ $t("tabs.brands_comisions") }}
                      </div>
                  </template>
                  
                  <b-list-group v-if="userData.brandsCommissions.length > 0">
                    <b-list-group-item variant="primary">
                      <b-row>
                        <b-col md="4"> {{ $t('user-list.text.general') }} </b-col>
                        <b-col md="4">
                          <v-select
                            class="bg-white"
                            v-model="brandsComisionGeneral.commissionTypes"
                            :options="typeComissionsOptions"
                            :clearable="false"
                            @change="
                              changeBrandsCommissionGeneral(
                                $event,
                                'commissionTypes'
                              )
                            "
                          />
                        </b-col>
                        <b-col md="4">
                          <money
                            v-model="brandsComisionGeneral.percent"
                            v-bind="{ suffix: ' %', masked: false }"
                            class="form-control"
                            @change="
                              changeBrandsCommissionGeneral($event, 'percent')
                            "
                          />
                        </b-col>
                      </b-row>
                    </b-list-group-item>

                    <b-list-group-item
                      v-for="(tr, indexTr) in userData.brandsCommissions"
                      :key="indexTr"
                    >
                      <b-row>
                        <b-col md="4">
                          {{ tr.nameAndProduct }}
                        </b-col>
                        <b-col md="4">
                          <v-select
                            v-model="tr.commissionTypes"
                            :options="typeComissionsOptions"
                            :clearable="false"
                          />
                        </b-col>
                        <b-col md="4">
                          <money
                            v-model="tr.percent"
                            v-bind="{ suffix: ' %', masked: false }"
                            class="form-control"
                          />
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                  <div v-else class="text-center">
                    <h1>{{ $t('user-list.text.noPoseeComisiones') }}</h1>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          @click="onSubmit()"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          <feather-icon icon="SaveIcon" class="mr-1" />
          {{ $t("buttons.save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          <feather-icon icon="XIcon" class="mr-1" />
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BRow,
  BSidebar,
  BTab,
  BTabs,
  TabsPlugin,
} from "bootstrap-vue";
import VeeValidate from 'vee-validate'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {computed, getCurrentInstance, ref, watch} from "@vue/composition-api";
import {alphaDash, alphaNum, dni, email, required,} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import { codeMultiple } from "../../../../forms/form-element/form-select/code";
import roleStoreModule from "../../../../pages/roles/roleStoreModule";
import clientStoreModule from "../../../../pages/clients/clientStoreModule";
import userStoreModule from "../../userStoreModule";
import whitelabelStoreModule from "../../../../pages/whitelabel/whitelabelStoreModule";
import { useToast } from "vue-toastification/composition";
import ComponentAddPlayer from "../ComponentAddPlayer.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import {translatableText, whitelabelPassRules} from "@core/utils/utils";
import { axiosErrorHandle } from '@core/utils/errorHandler'
import flatPickr from "vue-flatpickr-component";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import moment from "moment";
import flatpickr from 'flatpickr'

export default {
  components: {
    BTab,
    TabsPlugin,
    BTabs,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCardText,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ComponentAddPlayer,
    HierarchyUsers,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  watch: {
    isAddNewUserSidebarActive(newVal) {
      if (!newVal) {
        this.modeEdit = false;
        this.userData = this.blankUserData;
      } else {
        if (!this.modeEdit && this.userLogged.typeUser === 'Admin' && this.changeClient) {
          console.log('Se agrega usuario en la rama por defecto')
          this.selectUserHierarchyChangeRama({
              id: this.userLogged._id,
              title: this.userLogged.userName,
          })
        }
        this.getRoles();
        this.getClients();
      }
    },
  },

  setup(props, { emit }) {
    const ROLE_APP_STORE_MODULE_NAME = "app-role";
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME))
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);

    const rolesOptions = ref([]);
    const rolesAllowed = ref([]);
    const clientRoles = ref([]);
    const toast = useToast(ToastificationContent);
    const userLogged = ref(JSON.parse(localStorage.getItem("userData")));

    const clientsOptions = ref([]);
    const clientsAllowed = ref([]);
    const tabsTypeUserOptions = ["Player", "Agent"];

    const vm = getCurrentInstance()

    const getClients = async () => {
      store
        .dispatch("app-client/fetchClients", {
          perPage: 10000,
        })
        .then((response) => {
          clientsOptions.value = response.data.clients;
          if (!modeEdit.value) {
            // const client = clientsOptions.value.find(client => client._id === userData.value.client);
            // selectedClient(whitelabelSelect.value)
            userData.value.whitelabels = whitelabelSelect.value;
          }
          userData.value.client = whitelabelSelect.value.client;
        })
        .catch((value) => {
          console.log(value);
        });
    };

    const getRoles = () => {
      store
        .dispatch("app-role/fetchRoles")
        .then((response) => {
          rolesOptions.value = response.data.roles;
        })
        .catch(async (error) => {
          toast({
            message: await translatableText({text : axiosErrorHandle(error).toString()}),
            type: "is-danger",
            duration: 5000,
          });
        });
    };

    const users = ref([]);
    const usersForParent = ref([]);

    const currencies = ref([]);

    const blankUserData = {
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phone: "",
      country: "",
      birthdate: "",
      password: "",
      client: "",
      currencies: [],
      roles: [],
      platform: "admin",
      whitelabels: "",
      products: [],
      typeUser: "Player",
      commissions: [],
      brandsCommissions: [],
      branchAll: false,
      branch: "",
      affiliateCode: "",
      dni: "",
    };

    const whitelabelOptions = ref([]);
    const currenciesOptions = ref([]);
    const productsOptions = ref([]);
    whitelabelOptions.value = JSON.parse(localStorage.getItem("whitelabels"));

    const userData = ref(blankUserData);
    const resetuserData = () => {
      userData.value = blankUserData;
    };
    const selectUserChangeRama = ref({});

    const selectedProducts = (products) => {
      const productsComisions = products.map((product) => {
        return {
          productId: product.productId,
          commissionTypes: "Sale",
          percent: 0,
        };
      });
      userData.value.commissions = productsComisions;
    };

    const selectedBrands = (brands) => {
      const brandsComisions = brands.map((brand) => {
        return {
          brandId: brand.brandId,
          commissionTypes: "Sale",
          nameAndProduct: brand.name + ' - ' + brand.product.name,
          percent: 0,
        };
      });
      userData.value.brandsCommissions = brandsComisions;
      console.log(
        "🚀 ~ file: UserListAddNewcopy.vue ~ line 830 ~ selectedBrands ~ userData.value.brandsCommissions",
        userData.value.brandsCommissions
      );
    };


    const addMissingWhitelabelBrands = () => {
      const userBrandIds = new Set(userData.value.brandsCommissions.map(brand => brand.brandId));

      if (userData.value.whitelabels.brands) {
        userData.value.whitelabels.brands.forEach((brand) => {
          if (!userBrandIds.has(brand.brandId)) {
            userData.value.brandsCommissions.push({
              brandId: brand.brandId,
              nameAndProduct: brand.name + ' - ' + brand.product.name,
              commissionTypes: "Sale",
              percent: 0,
            });
          }
        });
      }
    };

    const whitelabelSelect = computed(() => {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    });

    const currencySelect = computed(() => {
      return store.state.whitelabelCurrencyNabvar.currency;
    });


    const setDataUtils = () => {
      // userData.value.whitelabels = whitelabelSelect.value._id;
      // console.log("🚀 ~ file: UserListAddNewcopy.vue ~ line 838 ~ setDataUtils ~ whitelabelSelect", whitelabelSelect.value)
      if (userLogged.value.typeUser == "Root") {
        productsOptions.value = whitelabelSelect.value.products;
        userData.value.products = whitelabelSelect.value.products;
        currenciesOptions.value = whitelabelSelect.value.currencies;
        userData.value.currencies = [currencySelect.value];
      } else {
        productsOptions.value = userLogged.value.products;
        userData.value.products = userLogged.value.products;

        currenciesOptions.value = userLogged.value.currencies;
        userData.value.currencies = [currencySelect.value];
        userData.value.roles = userLogged.value.roles.map((role) => {
          return role._id;
        });
      }

      selectedProducts(whitelabelSelect.value.products);
      selectedBrands(whitelabelSelect.value.brands);

      console.log(
        "🚀 ~ file: UserListAddNewcopy.vue ~ line 861 ~ setUtils ~ userData",
        userData.value
      );
    };
    setDataUtils();
    watch([whitelabelSelect, currencySelect], () => {
      setDataUtils();
    });

    const changeClient = computed(() => {
      return whitelabelSelect.value._id !== userData.value.whitelabels;
    });

    const modeEdit = ref(false);
    const tabIndex = ref(0);

    //watch modeEdit
    watch([modeEdit], () => {
      if (modeEdit.value) {
        userData.value.client = userData.value.client._id;
      }
    });

    // watch tabIndex
    watch([tabIndex], () => {
      if (!modeEdit.value) {
        if (tabIndex.value == 0) {
          userData.value.typeUser = "Player";
        } else if (tabIndex.value == 1) {
          userData.value.typeUser = "Agent";
        }
      }
    });

    const onSubmit = async () => {
      if (
        userData.value.typeUser !== "Player" &&
        userData.value.roles.length == 0
      ) {
        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user-list.text.pleaseSelectRol'),
            icon: "XIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        console.log(
          "🚀 ~ file: UserListAddNewcopy.vue ~ line 909 ~ onSubmit ~ userData",
          userData.value
        );

        let datosUser = {
          userName: userData.value.userName,
          firstName: userData.value.firstName,
          lastName: userData.value.lastName,
          email: userData.value.email,

          address: userData.value.address,
          phone: userData.value.phone,
          country: userData.value.country,
          birthdate: userData.value.birthdate,

          password: userData.value.password,
          client: userData.value.client,
          currencies: userData.value.currencies,
          roles:
            userData.value.typeUser !== "Player" ? [userData.value.roles] : [],
          platform: "admin",
          whitelabels: [userData.value.whitelabels._id],
          products: userData.value.products,
          typeUser: userData.value.typeUser,
          commissions: userData.value.commissions,
          brandsCommissions: userData.value.brandsCommissions,
          branch: selectUserChangeRama.value.id,
          affiliateCode: userData.value.affiliateCode,
          inputDni: userData.value.dni,
        };

        // console.log("🚀 ~ file: UserListAddNewcopy.vue ~ line 932 ~ onSubmit ~ datosUser", datosUser.brandsCommissions)

        if (modeEdit.value) {
          datosUser._id = userData.value._id;
          const response = await store.dispatch(
            "app-user/updateUser",
            datosUser
          );
          selectUserChangeRama.value = {};
        } else {
          datosUser.client = whitelabelSelect.value.client;
          const response = await store.dispatch("app-user/addUser", datosUser);
        }

        resetuserData();
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);

        if (modeEdit.value) {
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('user-list.messages.userEdited'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('user-list.messages.userCreated'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({text : axiosErrorHandle(error).toString()}),
            icon: "XIcon",
            variant: "danger",
          },
        });
      }
    };

    const selectedClient = async (client) => {
      // console.log("🚀 ~ file: UserListAddNewcopy.vue ~ line 906 ~ selectedClient ~ client", client.whitelabels)
      if (client.whitelabels.length == 1) {
        userData.value.whitelabels = client.whitelabels[0];
      } else if (client.whitelabels.length > 1) {
        whitelabelOptions.value = client.whitelabels;
      }
    };

    const autocomplete = () => {
      if (userData.value.userName.length > 0 && whitelabelSelect.value['fieldData'].email === false) {
        userData.value.email = `${userData.value.userName}@${userData.value.userName}.com`
      }
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);


    return {
      tabsTypeUserOptions,
      tabIndex,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      rolesOptions,
      clientsOptions,
      users,
      rolesAllowed,
      currencies,
      usersForParent,
      clientRoles,
      clientsAllowed,
      selectedClient,
      currenciesOptions,
      blankUserData,
      modeEdit,
      whitelabelOptions,
      userLogged,
      productsOptions,
      getRoles,
      selectedProducts,
      selectedBrands,
      selectUserChangeRama,
      getClients,
      changeClient,
      addMissingWhitelabelBrands,
      autocomplete
    };
  },
  data() {
    return {
      required,
      alphaNum,
      alphaDash,
      email,
      userIsAdmin: false,
      codeMultiple,
      name: "",
      typeComissionsOptions: ["Sale", "Profit"],
      showHeranchy: false,

      comisionGeneral: {
        commissionTypes: "Sale",
        percent: 0,
      },

      brandsComisionGeneral: {
        commissionTypes: "Sale",
        percent: 0,
      },

      rules: {
        required: (value) => !!value || this.$t("messages.required_field"),
        email: (v) => (v.length > 0 ? /.+@.+\..+/.test(v) : true) || this.$t("messages.email_error"),
      }
    };
  },
  computed: {
    whitelabelRule(){
      const whitelabelSelected = this.$store.state.whitelabelCurrencyNabvar.whitelabel;
      return whitelabelPassRules(whitelabelSelected)
    },
    userLoggedIsAdmin() {
      const userLogged = JSON.parse(localStorage.getItem("userData"));
      if (userLogged.is_admin == true) {
        return (this.userIsAdmin = true);
      } else {
        return (this.userIsAdmin = false);
      }
    },
    changeBranchActive() {
      if (this.selectUserChangeRama.name) {
        return true;
      } else {
        return false;
      }
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    tabsTypeUserOptionsComouted() {
      if (this.modeEdit && this.userData.typeUser == "Player") {
        return ["Player"];
      }
      if (this.modeEdit && this.userData.typeUser == "Admin") {
        return ["Admin"];
      }
      if (
        (this.modeEdit && this.userData.typeUser == "Agent") ||
        this.userData.typeUser == "Admin"
      ) {
        return ["Agent"];
      }
      return ["Player", "Agent"];
    },
  },
  methods: {
    yearDropdownPlugin,
    ShowSidebar(val) {
      this.$emit("update:is-add-new-user-sidebar-active", val);
    },
    methoEditUser(userDataEdit) {
      // console.log("🚀 ~ file: UserListAddNewcopy.vue ~ line 1077 ~ methoEditUser ~ userDataEdit", userDataEdit)

      this.userData = {
        parentUserName: userDataEdit.branch?.userName,
        roles: userDataEdit?.roles[0]?._id,
        typeUser: userDataEdit.typeUser,
        _id: userDataEdit._id,
        password: "",
        userName: userDataEdit.userName,
        firstName: userDataEdit.firstName,
        lastName: userDataEdit.lastName,
        email: userDataEdit.email,

        address: userDataEdit.address,
        phone: userDataEdit.phone,
        country: userDataEdit.country,
        birthdate: userDataEdit.birthdate,

        client: userDataEdit.client,
        currencies: userDataEdit.currencies,
        platform: "admin",
        whitelabels: userDataEdit.whitelabels?.[0],
        products: userDataEdit.products,
        commissions: userDataEdit.commissions,
        brandsCommissions: userDataEdit.brandsCommissions,
        affiliateCode: userDataEdit.affiliateCode,
        dni: userDataEdit.inputDni,
      };
      console.log(
        "🚀 ~ file: UserListAddNewcopy.vue ~ line 1119 ~ methoEditUser ~ this.userData",
        this.userData
      );

      this.modeEdit = true;

      this.addMissingWhitelabelBrands()
     
    },
    showHeranchyChangeRama() {
      this.showHeranchy = true;
    },
    changeBrandsCommissionGeneral(val, type) {
      console.log(
        "🚀 ~ file: UserListAddNewcopy.vue ~ line 1128 ~ changeBrandsCommissionGeneral ~ this.userData.brandsCommissions",
        this.userData.brandsCommissions
      );
      if (
        !this.userData.brandsCommissions ||
        this.userData.brandsCommissions.length <= 0
      )
        this.selectedBrands(this.userData.whitelabels.brands);
      if (type == "commissionTypes") {
        this.userData.brandsCommissions.forEach((commissionItem) => {
          commissionItem.commissionTypes = val;
        });
      } else {
        this.userData.brandsCommissions.forEach((commissionItem) => {
          commissionItem.percent = val;
        });
      }
    },
    changeCommissionGeneral(val, type) {
      if (type == "commissionTypes") {
        this.userData.commissions.forEach((commissionItem) => {
          commissionItem.commissionTypes = val;
        });
      } else {
        this.userData.commissions.forEach((commissionItem) => {
          commissionItem.percent = val;
        });
      }
    },
    showHeranchyChangeRamaFalse(val) {
      this.showHeranchy = false;
    },
    selectUserHierarchyChangeRama(user) {
      this.selectUserChangeRama = {
        id: user.id,
        name: user.title,
      };
      this.showHeranchy = false;
    },
    generateAffiliateCode() {
      // format XXX-XXX-XXX letras mayusculas y numeros aleatorios
      let code = "";
      let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let numbers = "0123456789";
      for (let i = 0; i < 3; i++) {
        code += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      code += "-";
      for (let i = 0; i < 3; i++) {
        code += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }
      code += "-";
      for (let i = 0; i < 3; i++) {
        code += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      this.userData.affiliateCode = code;
    },
  },
  mounted() {
    this.tabIndex = 0;

    //To make the focusIn of the flatPick work in the modal to change the year
    //When the component displays in a Bootstrap modal (without the "select" element plugin for years) 
    // you cannot click inside the input to select the years
    document.addEventListener('focusin', (e) => {
      if (e.target.closest(".flatpickr-calendar") !== null) {
        e.stopImmediatePropagation();
      }
    });

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#add-new-user-sidebar___BV_modal_header_ {
  // background: linear-gradient(40deg, #e5710a, #f16029);
}

#add-new-user-sidebar___BV_modal_title_ {
  color: white !important;
}

.nav-tabs .nav-link.active {
  color: #001862;
}

.nav-pills .nav-link.active {
  background-color: #02cce0;
  border-color: #02cce0;
  box-shadow: 0 4px 18px -4px #001862;
}

.nav-tabs .nav-link:after {
  background: linear-gradient(30deg, #02cce0, #001862) !important;
}

[dir] .nav-pills li {
  width: 50%;
}
</style>
